$(function(){

$("#send-message-to-owner").on("click", function(){

var owner_id = $("#owner_id").val(),
    name = $("#message-name").val(),
    invitati = $("#message-invitati").val(),
    message = $("#message-text").val(),

    $,ajax ;({
        url: "/owner/message",
    method: "POST",
    dataType: "json",
    data: {
        owner_id: owner_id,
        name: name,
        invitati: invitati,
        message: message,

    }

});
});
});